import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

const LinearProgressBar = () => {
  const classes = useStyles();

  return (
    <LinearProgress
      classes={{
        root: classes.root,
      }}
    />
  );
};

export default LinearProgressBar;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: "8px 8px 0px 0px",
    width: "100%",

    "& .MuiLinearProgress-bar": {
      backgroundColor: `${theme.palette.secondary.grey3} !important`,
    },
  },
}));
